<template>
  <div id="target-wrapper">
    <component
      v-if="assetType !== null"
      :is="getTargetComponent"
      :associatedId="associatedId"
      :assetSrc="assetSrc"
      :assetSrc2="assetSrc2"
      :assetDescription="assetDescription"
      :targetName="targetName"
      :targetDescription="targetDescription"
      :points="points"
      :awardedPoints="awardedPoints"
      :thumbnailSrc="thumbnailSrc"
      :specialFlag="specialFlag"
      :scan="scan"
      @ended="handleVideoEnd"
    />
    <CongratsModal v-if="showWinPopup" @close="showWinPopup = false" />
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import TargetText from '@/components/TargetText.vue';
import TargetImage from '@/components/TargetImage.vue';
import TargetVideo from '@/components/TargetVideo.vue';
import TargetLink from '@/components/TargetLink.vue';
import TargetPersonalityQuiz from '@/components/TargetPersonalityQuiz.vue';
import TargetTrivia from '@/components/TargetTrivia.vue';
import CongratsModal from '@/components/CongratsModal.vue';

export default {
  components: {
    CongratsModal,
  },
  data() {
    return {
      isComplete: null,
      awardedPoints: null,
      targetName: null,
      targetDescription: null,
      points: null,
      iconSrc: null,
      associatedId: null,
      assetType: null,
      assetSrc: null,
      thumbnailSrc: null,
      assetDescription: null,
      specialFlag: null,
      scan: null,
      showWinPopup: false,
      showWinPopupLater: false,
      didShowWinPopup: false,
    };
  },
  mounted() {
    this.getTargetData();
  },
  computed: {
    getTargetComponent() {
      switch (this.assetType) {
        case 'image':
          return TargetImage;
        case 'video':
          return TargetVideo;
        case 'link':
          return TargetLink;
        case 'quiz':
          return TargetPersonalityQuiz;
        case 'trivia':
          return TargetTrivia;
        default:
          return TargetText;
      }
    },
    isFrench() {
      return this.$store.state.locale === 'fr';
    },
    triviaUUID() {
      return process.env.VUE_APP_TRIVIA_UUID;
    },
  },
  watch: {
    $route(prev, next) {
      if (prev.name === next.name) {
        this.$nextTick(() => {
          window.location.reload();
        });
      }
    },
  },
  methods: {
    async getTargetData() {
      // this.scan = !!this.$route.query?.scan;
      this.scan = true;
      this.$router.replace({ query: null });
      const { target, isComplete, showWinPopup, awardedPoints } = (
        await ApiService.post(`/target/${this.$route.params.id}/scan`, { scan: this.scan })
      ).data;
      const { name, nameFR, description, descriptionFR, points, iconSrc, targetAsset } = target;
      const {
        associatedId,
        assetType,
        assetSrc,
        assetSrc2,
        thumbnailSrc,
        assetDescription,
        assetDescriptionFR,
        specialFlag,
      } = targetAsset;

      if (showWinPopup) {
        this.showWinPopupLater = true;
      }

      this.isComplete = isComplete;
      this.awardedPoints = awardedPoints;
      this.targetName = (this.isFrench ? nameFR : name) || name || nameFR;
      this.targetDescription = (this.isFrench ? descriptionFR : description) || description || descriptionFR;
      this.points = points;
      this.iconSrc = iconSrc;
      this.associatedId = associatedId;
      this.assetType = assetType;
      this.assetSrc = assetSrc;
      this.assetSrc2 = assetSrc2;
      this.thumbnailSrc = thumbnailSrc;
      this.assetDescription =
        (this.isFrench ? assetDescriptionFR : assetDescription) || assetDescription || assetDescriptionFR;
      this.specialFlag = specialFlag;
    },
    handleVideoEnd(e) {
      if (this.showWinPopupLater && !this.didShowWinPopup) {
        this.showWinPopup = true;
        this.didShowWinPopup = true;
        this.$store.commit('setCongratsAsCamera');
      }
      e.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  min-height: calc(100vh - 73px);
  background: rgba(36, 58, 83, 0.8);
  backdrop-filter: blur(5px);
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 291px;
    max-height: 90%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    // max-height: 479.406px;
    // .modal-content {
    //   overflow-y: scroll;
    // }
    .modal-body {
      position: relative;
      padding: 32px;
      color: #fff;
      background-color: #243a53;
      h1 {
        margin-top: 28px;
        margin-bottom: 22px;
      }
      p {
        margin-top: 0;
        margin-bottom: 32px;
      }
      h2 {
        margin-top: 62px;
        margin-bottom: 23px;
      }
      .button {
        // white-space: nowrap;
        max-width: 100%;
        padding: 11px 16px;
        margin: 0 auto;
      }
    }
  }
}
.x-icon {
  position: fixed;
  top: 27px;
  right: 27px;
  width: 20px;
}
.video-frame {
  width: 150px;
}
</style>
