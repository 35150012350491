<template>
  <div class="target">
    <!-- <XIcon class="x-icon" @click="goHome" /> -->
    <h1>{{ targetName }}</h1>
    <!-- <hr class="separator" /> -->
    <div class="video-container">
      <video
        ref="video"
        class="video-frame"
        :poster="fullThumbnailUrl"
        autoplay
        muted
        playsinline
        @ended="$emit('ended', $refs.video)"
      >
        <!-- <source src="../test.mp4" type="video/mp4" /> -->
        <source :src="fullAssetUrl" :type="videoSrcType" />
        <source v-if="assetSrc2" :src="fullAsset2Url" type="video/webm" />
      </video>
    </div>

    <p class="paragraph">{{ targetDescription }}</p>
    <Points v-if="scan" :points="awardedPoints ? points : 0" :attempted="$t('scan.already')" />
    <PassportButton to="/scanner" :label="$t('scan.again')" />
  </div>
</template>

<script>
// import XIcon from '@/assets/icons/x_icon.svg';
import Points from '@/components/Points.vue';
import PassportButton from './PassportButton.vue';

export default {
  name: 'TargetVideo',
  components: {
    // XIcon,
    Points,
    PassportButton,
  },
  props: {
    targetName: {
      type: String,
      required: true,
    },
    targetDescription: {
      type: String,
      required: true,
    },
    assetSrc: {
      type: String,
      required: true,
    },
    assetSrc2: {
      type: String,
      required: false,
    },
    points: {
      type: Number,
      required: true,
    },
    awardedPoints: {
      type: Boolean,
      required: true,
    },
    thumbnailSrc: {
      type: String,
      required: true,
    },
    scan: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    fullAssetUrl() {
      return `${process.env.VUE_APP_ASSET_URL}/${this.assetSrc}`;
    },

    fullAsset2Url() {
      return `${process.env.VUE_APP_ASSET_URL}/${this.assetSrc2}`;
    },

    fullThumbnailUrl() {
      return `${process.env.VUE_APP_ASSET_URL}/${this.thumbnailSrc}`;
    },

    videoSrcType() {
      if (this.assetSrc2) return 'video/mp4; codecs="hvc1"'; // has transparent video
      return 'video/mp4';
    },
  },
  methods: {
    goHome() {
      this.$router.push('/stamps');
    },
  },
};
</script>

<style lang="scss" scoped>
.target {
  position: relative;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100svh - 3em - 39px - 40px - 69px * 2);
  padding: 1em;
  padding-bottom: calc(3em + 39px);
  // background-image: url(../assets/images/bg_main_repeating@2x.png);
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  margin-top: -32px;
}
.paragraph {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  // text-align: left;
}
.x-icon {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 4em;
  height: 1.2em;
  margin-top: 1em;
}
.separator {
  width: 6em;
  margin-bottom: 2em;
}
.video-frame {
  width: 80%;
}
</style>
